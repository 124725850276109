import React from "react"
import Layout from "../components/layout"
import Row from "../components/row"
import bg from "../images/backgrounds/detail-bg.png"
import { graphql } from "gatsby"
import PaginationLinks from "../components/paginationLinks"
import text from "../data/nl"
import NewsBlock from "../components/newsBlock"
import SEO from "../components/seo"
import pagination from "../config/pagination.conf"

const NewsPage = ({data}) => (
  <Layout bigfoot={false}>
    <SEO title={text.news.meta.title} description={text.news.meta.description}/>
    <Row image={bg} size="small" classes=" pt-200 pb-100" id="NewsList">
      <h1 className="t-50 uppercase bold column col-12">{text.news.title}</h1>
      <ul className="hbox space-between list-style-none">
        {data.allNews.edges.map((article) =>
          <li className="column col-4 mt-40" key={article.node.title}>
            <NewsBlock article={article.node}/>
          </li>
        )}
      </ul>
      <div className="col-12 column">
        <PaginationLinks currentPage={1} numberOfPages={Math.ceil(data.allNews.totalCount / pagination.postsPerPage)}/>
      </div>
    </Row>
  </Layout>
)


export const newsQuery = graphql`
  query newsQuery {
    allNews( limit: 9 ) {
      totalCount
      edges {
        node {
          title
          thumbnail
          background
          content
          excerpt
          path
          date(formatString: "dd-MM-yyyy")
          tags
        }
      }
    }
  }
`
export default NewsPage
